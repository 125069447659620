define('ember-route-action-helper/helpers/route-action', ['exports', 'ember-route-action-helper/-private/internals'], function (exports, _internals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getCurrentInfos(router) {
    let routerLib = router._routerMicrolib || router.router;

    return {
      currentInfos: routerLib.currentRouteInfos || routerLib.currentHandlerInfos,
      mapBy: routerLib.currentRouteInfos && 'route' || 'handler'
    };
  }

  function getRoutes(router) {
    var _getCurrentInfos = getCurrentInfos(router);

    const currentInfos = _getCurrentInfos.currentInfos,
          mapBy = _getCurrentInfos.mapBy;

    return Ember.A(currentInfos).mapBy(mapBy).reverse();
  }

  function getRouteWithAction(router, actionName) {
    let action;
    let handler = Ember.A(getRoutes(router)).find(route => {
      let actions = route.actions || route._actions;
      action = actions[actionName];

      return typeof action === 'function';
    });

    return { action, handler };
  }

  exports.default = Ember.Helper.extend({
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }).readOnly(),

    compute([actionName, ...params]) {
      let router = Ember.get(this, 'router');
      (false && !(router) && Ember.assert('[ember-route-action-helper] Unable to lookup router', router));


      Ember.runInDebug(() => {
        var _getRouteWithAction = getRouteWithAction(router, actionName);

        let handler = _getRouteWithAction.handler;
        (false && !(handler) && Ember.assert(`[ember-route-action-helper] Unable to find action ${actionName}`, handler));
      });

      let routeAction = function routeAction(...invocationArgs) {
        var _getRouteWithAction2 = getRouteWithAction(router, actionName);

        let action = _getRouteWithAction2.action,
            handler = _getRouteWithAction2.handler;

        let args = params.concat(invocationArgs);
        return Ember.run.join(handler, action, ...args);
      };

      routeAction[_internals.ACTION] = true;

      return routeAction;
    }
  });
});