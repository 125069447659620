define("ember-handle-keys-helper/helpers/handle-keys", ["exports", "@ember/component/helper", "@ember/debug", "ember-handle-keys-helper/constants"], function (_exports, _helper, _debug, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function handleKeys(args, _ref) {
    let {
      preventDefault,
      stopPropagation
    } = _ref;
    const {
      handler,
      targetKeys
    } = parseArgs(args);
    (false && !(targetKeys.length && targetKeys.every(k => typeof k === 'string')) && (0, _debug.assert)(`Expected an optional handler following by target keys, but got ${args}`, targetKeys.length && targetKeys.every(k => typeof k === 'string')));
    return function keyboardEventHandler(event) {
      (false && !(event instanceof KeyboardEvent) && (0, _debug.assert)(`Expected an event to be KeyboardEvent, but got ${event}`, event instanceof KeyboardEvent));
      let key = _constants.IE_KEYS_FIX_MAP[event.key] || event.key;
      if (targetKeys.includes(key)) {
        if (preventDefault) {
          event.preventDefault();
        }
        if (stopPropagation) {
          event.stopPropagation();
        }
        if (handler) {
          handler(event);
        }
      }
    };
  });
  function parseArgs(_ref2) {
    let [handlerOrKey, ...keys] = _ref2;
    return typeof handlerOrKey === 'function' ? {
      handler: handlerOrKey,
      targetKeys: keys
    } : {
      handler: null,
      targetKeys: [handlerOrKey, ...keys]
    };
  }
});